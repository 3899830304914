<template>
  <v-form
    v-model="valid"
    id="item-form"
  >
    <v-expansion-panels v-model="selectedPanel">
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('basicInfo') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            filled
            dense
            :label="$t('title') + ' *'"
            v-model="value.title"
            validate-on-blur
            :rules="[rules.required, rules.whiteSpaceString]"
          />

          <v-textarea
            filled
            dense
            v-if="showInput"
            :label="$t('description')"
            v-model="descriptionValue"
          />
        
          <v-autocomplete
            filled
            multiple
            dense
            :label="$t('categories')"
            :items="categories"
            item-text="name"
            item-value="uuid"
            v-model="value.categories"
            chips
            deletable-chips
            small-chips
          />

          <PriceField
            filled
            dense
            :label="$t('price') + ' *'"
            :show-tax-selection="true"
            v-model="value.price"
          />

          <v-row v-if="value.type === 'giftcard'">
            <div v-if="paymentMethodInstanceMissingWarning">
              <v-alert
                :title="$t('errorOccured')"
                density="compact"
                type="warning"
              >
                {{ paymentMethodInstanceMissingWarning }}
              </v-alert>
            </div>

            <v-col
              class="pt-0 pb-0"
              :cols="8"
            >
              <v-autocomplete
                filled
                dense
                :label="$t('paymentMethod')"
                :items="paymentMethodInstances"
                item-text="title"
                item-value="legacyId"
                v-model="value.payment_instance"
                :disabled="!moreThanOnePaymentMethodInstanceAvailable"
              />
            </v-col>

            <v-col
              class="pt-0 pb-0"
              :cols="4"
            >
              <v-text-field
                filled
                dense
                :label="$t('validity') + ' (' + $t('months') + ')'"
                v-model.number="value.validity"
              />
            </v-col>
          </v-row>

          <v-row v-if="value.type === 'coupon'">
            <v-col
              class="pt-0 pb-0"
              :cols="8"
            >
              <v-autocomplete
                filled
                multiple
                dense
                :label="$t('redeemableItems')"
                :items="items"
                :item-text="item =>`${item?.title}  (${item?.type}${item?.status ? '' : ` - Inactive`})`"
                item-value="uuid"
                v-model="value.redeemableItems"
                chips
                deletable-chips
                small-chips
              />
            </v-col>
          

            <v-col
              class="pt-0 pb-0"
              :cols="4"
            >
              <v-text-field
                ref="amount"
                filled
                dense
                :label="$t('maxRedemptions')"
                v-model.number="value.max_redemptions"
              />
            </v-col>
          </v-row>

          <div class="mb-5">
            {{ $t('images') }}
          </div>

          <v-spacer />

          <v-alert
            :title="$t('errorOccurred')"
            density="compact"
            type="warning"
            v-if="!itemImage && !uploadWidget && initializeError"
          >
            {{ $t('imageUploadError') }}
          </v-alert>

          <v-btn
            v-if="!itemImage && !initializeError"
            :loading="!uploadWidget || loadingUploadWidget"
            size="x-large"
            height="4rem"
            min-width="8rem"
            @click="openUploadWidget"
          >
            {{ $t('upload') }}
          </v-btn>

          <p
            v-if="!itemImage && !initializeError"
            height="4rem"
            min-width="8rem"
            class="font-weight-light mt-5"
          >
            {{ $t('uploadDisclaimer') }}
          </p>

          <v-row
            v-else-if="itemImage"
            justify="center"
          >
            <v-col
              cols="4"
              align="center"
            >
              <v-img
                :src="itemImage"
                width="150px"
                height="150px"
              />
            </v-col>
            <v-col
              cols="8"
              class="mt-8"
              align="start"
            >
              <v-btn
                outlined
                height="3rem"
                width="8rem"
                color="red"
                @click="removeImageFromItem"
              >
                {{ $t('remove') }}
              </v-btn>
            </v-col>
          </v-row>


          <v-checkbox
            v-if="value.type === 'giftcard'"
            :label="$t('sendToCustomer')"
            v-model="value.send_to_customer"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>


      <v-expansion-panel v-if="value.type === 'product' || value.type === 'giftcard' || value.type === 'coupon'">
        <v-expansion-panel-header>{{ $t('advancedPricing') }}</v-expansion-panel-header>

        <v-expansion-panel-content>
          <PriceField
            v-if="value.type === 'product'"
            filled
            dense
            :label="$t('costPrice')"
            :selected-tax-rate="selectedTaxRate"
            v-model="value.cost_price"
          />

          <PriceField
            v-if="value.type === 'product' || value.type === 'giftcard'"
            filled
            dense
            :label="$t('salePrice')"
            :selected-tax-rate="selectedTaxRate"
            v-model="value.sale_price"
          />

          <v-checkbox 
            v-if="value.type === 'product' || value.type === 'giftcard'"
            :label="$t('excludeDiscounts')"
            v-model="value.exclude_discounts"
          />

          <v-checkbox 
            class="mt-0 pt-0"
            v-if="value.type === 'product'"
            :label="$t('payWhatYouWant')"
            v-model="value.payWhatYouWant"
          />
        
          <v-checkbox 
            class="mt-0 pt-0"
            v-if="value.type === 'product'"
            :label="$t('requirePopUp')"
            v-model="value.popup"
          />

          <div v-if="false">
            <p class="mb-5 mt-1">
              {{ $t('weekdayPricing') }}
            </p>

            <v-spacer />

            <WeekdayPrices
              :weekday-prices="value.weekdayPrices"
              @addNewWeekdayPrice="addNewWeekdayPrice"
              @removeWeekdayPrice="removeWeekdayPrice"
              @updateWeekdayPrice="updateWeekdayPrice"
            />
          </div>
          

          <p class="mb-5">
            {{ $t('locationPrices') }}
          </p>

          <template v-for="(location, index) in locations">
            <v-text-field
              :label="location.label"
              :key="location.uuid"
              outlined
              small
              :suffix="value.price.currency_code"
              v-model.number="value.locationPrices[index].price.amount"
            />
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="value.type === 'product' || value.type === 'event'">
        <v-expansion-panel-header>{{ $t('variations') }}</v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-autocomplete
            filled
            dense
            :label="$t('variationSets')"
            :items="variationSets"
            item-text="label"
            item-value="uuid"
            multiple
            v-model="value.variation_sets"
            chips
            deletable-chips
            small-chips
            ref="autoComplete1"
            @change="clearSearchInput1"
          />

          <v-autocomplete
            filled
            dense
            :label="$t('defaultVariations')"
            :items="variationsInSelectedVariationSets"
            :item-text="item =>`${item?.label} - ${item?.price?.amount ? item.price.amount : 0} - ${item.attribute.name}`"
            item-value="uuid"
            multiple
            :no-data-text="$t('missingVariationSet')"
            v-model="value.default_variations"
            chips
            deletable-chips
            small-chips
            ref="autoComplete2"
            @change="clearSearchInput2"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- <v-expansion-panel v-if="showInput">
        <v-expansion-panel-header>{{ $t('shipping') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <Shipping />
        </v-expansion-panel-content>
      </v-expansion-panel> -->

      <!-- <v-expansion-panel v-if="showInput">
        <v-expansion-panel-header>{{ $t('availability') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <Availability v-model="value.availability" />
        </v-expansion-panel-content>
      </v-expansion-panel> -->

      <!-- <v-expansion-panel v-if="showInput && uiFields && uiFields.length">
        <v-expansion-panel-header>{{ value.type.slice(0,1).toUpperCase() + value.type.slice(1) }} {{ $t('typeSpecificSettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <template v-for="(field, index) in uiFields">
              <v-col
                :cols="field.cols || '12'"
                :key="index"
              >
                <v-text-field
                  v-if="field.type === 'textfield'"
                  filled
                  dense
                  v-model="value.uiValues[field.key]"
                  :label="field.label"
                  :placeholder="field.placeholder"
                  :hint="field.hint"
                  :persistent-hint="!!field.hint"
                />
                <v-textarea
                  v-if="field.type === 'textarea'"
                  filled
                  dense
                  v-model="value.uiValues[field.key]"
                  :label="field.label"
                  :placeholder="field.placeholder"
                  :hint="field.hint"
                  :persistent-hint="!!field.hint"
                />
                <v-text-field
                  v-if="field.type === 'number'"
                  filled
                  dense
                  type="number"
                  :placeholder="field.placeholder"
                  v-model="value.uiValues[field.key]"
                  :label="field.label"
                  :hint="field.hint"
                  :persistent-hint="!!field.hint"
                />
                <v-select
                  v-if="field.type === 'select'"
                  filled
                  dense
                  :items="field.options"
                  :placeholder="field.placeholder"
                  item-value="key"
                  item-text="label"
                  v-model="value.uiValues[field.key]"
                  :label="field.label"
                  :hint="field.hint"
                  :persistent-hint="!!field.hint"
                />
                <v-switch
                  v-if="field.type === 'switch'"
                  dense
                  v-model="value.uiValues[field.key]"
                  :label="field.label"
                  :hint="field.hint"
                  :persistent-hint="!!field.hint"
                />
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel> -->

      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('availability') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-switch
            v-model="sellOnline"
            :label="$t('sellOnline')"
            persistent-hint
            :hint="$t('sellOnlineInfo')"
          />

          <v-switch
            v-model="sellInStore"
            :label="$t('sellInStore')"
            persistent-hint
            :hint="$t('sellInStoreInfo')"
          />

          <v-switch
            v-model="sellInEtags"
            :label="$t('sellInEtags')"
            persistent-hint
            :hint="$t('sellInEtagsInfo')"
          />

          <v-switch
            v-model="sellInKiosk"
            :label="$t('sellInKiosk')"
            persistent-hint
            :hint="$t('sellInKioskInfo')"
          />

          <v-autocomplete
            v-model="sellInLocations"
            class="location-selection pt-3"
            :label="Array.isArray(sellInLocations) && sellInLocations.length > 0 ? $t('selectedLocations') : $t('allLocations')"
            :items="locations"
            item-text="label"
            item-value="uuid"
            persistent-hint
            filled
            dense
            validate-on-blur
            chips
            small-chips
            deletable-chips
            multiple
            :hint="$t('sellInLocationsInfo')"
          />

          <v-row dense>
            <OpeningHours
              :opening-hours="value.openingHours"
              @addNewOpeningHour="addNewOpeningHour"
              @sameAsPrevious="copyOpeningHoursFromPreviousWeekday"
              empty-is-default-open
            />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('accounting') }} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            filled
            dense
            :label="$t('sku')"
            v-model="value.sku"
            validate-on-blur
            :rules="[rules.required, rules.whiteSpaceString]"
          >
            <template v-slot:append>
              <v-icon
                @click="refreshSku"
              >
                mdi-refresh
              </v-icon>
            </template>
          </v-text-field>

          <v-textarea
            filled
            dense
            auto-grow
            rows="1"
            v-if="showInput && value.type === 'product'"
            :label="$t('barcode')"
            v-model="value.barcode"
          />

          <v-textarea
            filled
            dense
            auto-grow
            rows="1"
            v-if="showInput"
            :label="$t('accountingKey')"
            v-model="value.accountingKey"
          />

          <v-autocomplete
            filled
            dense
            :label="$t('mainCategory')"
            :items="categories"
            item-text="name"
            item-value="uuid"
            v-model="value.mainCategory"
            chips
            deletable-chips
            small-chips
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>

/**
 * @property {String} sku
 * @property {Object} cost_price
 * @property {Object} sale_price
 */

// Import the basic building blocks
// import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
// import { Bold, Italic, Underline } from 'tiptap-extensions'
import PriceField from './PriceField'
// import Availability from "@/components/item/Availability"
// import Shipping from "@/components/item/Shipping"
// import SeasonalPrices from "@/components/common/pricing/SeasonalPrices"
import WeekdayPrices from "@/components/common/pricing/WeekdayPrices"
import OpeningHours from "@/components/common/OpeningHours"

export default {
	name: 'ItemForm',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
	components: {
    // Shipping,
    // Availability,
    // EditorContent,
    // EditorMenuBar,
    PriceField,
    // SeasonalPrices,
    WeekdayPrices,
    OpeningHours
},
	data() {
		return {
		  // Create an `Editor` instance with some default content. The editor is
			// then passed to the `EditorContent` component as a `prop`
			// editor: new Editor({
			// 	content: '<p>This is just a boring paragraph</p>',
			// 	extensions: [
			// 		new Bold(),
			// 		new Italic(),
			// 		new Underline()
			// 	],
			// }),
      loadingUploadWidget: false,
      uploadWidget: null,
      valid: false,
      selectedPanel: 0,

      showInput: true,

			processing: false,

      initializeError: false,

      paymentMethodInstanceMissingWarning: null,
      
      seasonalPriceObject: {
        startInMillis: null,
        endInMillis: null,
        price: {
          amount: 0,
          currency_code: null
        },
        active: true
      },
      weekdayPriceObject : {
        weekday: null,
        hourStart: null,
        hourEnd: null,
          price: {
            amount: 0,
            currency_code: null
          },
        active: true
      }
		}
	},
	computed: {
    itemImage() {
        if (this.value.imageUrl) {
          return this.value.imageUrl
        }
        
        if (Array.isArray(this.value.images) && this.value.images.length > 0 && this.value.images[0].file && this.value.images[0].file.sources && this.value.images[0].file.sources.original) {
          return this.value.images[0].file.sources.original
        }

        return this.value.imageUrl
    },
    descriptionValue: {
      get() {
        if (this.value?.description?.value) {
          return this.value.description.value.replace(/<\/?[^>]+(>|$)/g, "")
        } 
        return ''
      },
      set(value) {
        this.value.description.value = value
      }
    },
    sellOnline: {
      get() {
        if(typeof this.value.sellOnline === 'undefined' || this.value.sellOnline === null) {
          return true
        }
        return this.value.sellOnline
      },
      set(value) {
        this.value.sellOnline = value
      }
    },
    sellInStore: {
      get() {
        if(typeof this.value.sellInStore === 'undefined' || this.value.sellInStore === null) {
          return true
        }
        return this.value.sellInStore
      },
      set(value) {
        this.value.sellInStore = value
      }
    },
    sellInEtags: {
      get() {
        if(typeof this.value.sellInEtags === 'undefined' || this.value.sellInEtags === null) {
          return false
        }
        return this.value.sellInEtags
      },
      set(value) {
        this.value.sellInEtags = value
      }
    },
    sellInKiosk: {
      get() {
        if(typeof this.value.sellInKiosk === 'undefined' || this.value.sellInKiosk === null) {
          return true
        }
        return this.value.sellInKiosk
      },
      set(value) {
        this.value.sellInKiosk = value
      }
    },
    sellInLocations: {
      get() {
        return this.value.sellInLocations
      },
      set(locationUUIDs) {
        this.value.sellInLocations = locationUUIDs
      }
    },
    imageIsDrualImage() {
      return !this.itemImage?.includes('https://res.cloudinary.com/salescloud/')
    },
    selectedTaxRate() {
      return this.value?.price?.taxRate
    },
    items() {
      return [...this.$store.state.items].sort((a, b) => {
        if (a.type === 'product') {
          return -1
        }
        if (b.type === 'product') { 
          return 1
        }
        if (a.type < b.type) {
          return -1
        }
        if (a.type > b.type) {
          return 1
        }
        return 0
      })
    },
    moreThanOnePaymentMethodInstanceAvailable() {
      return this.paymentMethodInstances.length > 1
    },
    paymentMethodInstances() {
      return this.$store.state.paymentMethodInstances.filter(pmi => pmi?.status === true && pmi?.namespace === 'giftcard')
    },
		categories() {
			return this.$store.state.categories
		},
    variationSets() {
      return this.$store.state.variations ? this.$store.state.variations.filter(variation => variation?.type === 'variation-set').sort() : []
    },
    variationsInSelectedVariationSets() {
      // Return the variations available for the selected variations or the already selected default_variations on the product.
      return this.$store.state.variations ? this.$store.state.variations.filter(variation => (variation?.type === 'product' && this.value?.variation_sets?.find(variationSet => typeof variationSet === 'string' ? variationSet === variation?.owner_id : variationSet.uuid === variation?.owner_id))).sort() : []
    },
    cloudinaryFolderName() {
      return `${this.$store.state.organization.uuid}/products`
    },
		itemTypes() {
			return this.$store.state.itemTypes
		},
		uiFields() {
			return this.itemTypes.find(itemType => itemType.namespace === this.value.type).uiFields
		},
    rules() {
      return this.$store.getters.rules
    },
    organization() {
      return this.$store.state.organization
    },
    locations() {
      const locationList = this.$store.state.locations.filter(location => location !== null && location !== undefined && location.label !== null && location.label !== undefined && location.label !== '' && location.active)
      locationList.forEach(location => {
        location.label = `${location.label} ${location.description ? `(${location.description})` : ''}`
      })
      return locationList
    },
    organizationIsAllowedToSeeExperimentalFeatures() {
      const organizationUUIDs = [
        '06a69a1f-8164-422e-b7d8-3753e04f6464', // Hvammsvík
        '2baf8f91-c585-4092-a716-15724a98b76f' // Mr. Green
      ]
      if(typeof this.organization !== 'undefined' && this.organization !== null) {
        if(typeof this.organization.uuid !== 'undefined' && this.organization.uuid !== null) {
          return organizationUUIDs.includes(this.organization.uuid)
        }
      }
      return false
    }
	},
	methods: {
    clearSearchInput1() {
      this.$nextTick(() => {
        this.$refs.autoComplete1.searchInput = ''
        this.$refs.autoComplete1.focus()
      })
    },
    clearSearchInput2() {
      this.$nextTick(() => {
        this.$refs.autoComplete2.searchInput = ''
        this.$refs.autoComplete2.focus()
      })
    },
    refreshSku() {
      const length = 16
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      this.value.sku = result
    },
    removeImageFromItem() {
      if (this.imageIsDrualImage) {
        this.value.removeLegacyImage = true
      }
      this.value.imageUrl = null
      this.value.images = []
    },
    uploadComplete(imageInfo) {
      this.loadingUploadWidget = false
      this.value.imageUrl = imageInfo.secure_url
    },
    openUploadWidget() {
      try {
        this.loadingUploadWidget = true
        this.uploadWidget.open()
      }catch(e) {
        this.loadingUploadWidget = false
        console.log(e)
      }
    },
    copyOpeningHoursFromPreviousWeekday(weekday) {
      if (!this.value.openingHours || !Array.isArray(this.value.openingHours)) {
        return
      }
      const previousWeekday = weekday === '0' ? 6 : ((weekday * 1) - 1)
      const previousWeekdayOpeningHours = this.value.openingHours.filter(openingHour => (openingHour.day * 1) === previousWeekday)
      this.value.openingHours = this.value.openingHours.filter(openingHour => (openingHour.day * 1) !== (weekday * 1))
      if(previousWeekdayOpeningHours.length > 0) {
        for(let i = 0; i < previousWeekdayOpeningHours.length; i++) {
          let openingHour = JSON.parse(JSON.stringify(previousWeekdayOpeningHours[i]))
          openingHour.day = weekday + ''
          this.value.openingHours.push(openingHour)
        }
      }
    },
		create() {
			this.processing = true
			this.$store.dispatch('createItem', JSON.parse(JSON.stringify(this.value))).then((itemUpdated) => {
				this.$emit('created', itemUpdated)
			})
					.finally(() => {
						this.processing = false
					})
		},
		update() {
			this.processing = true

			this.$store.dispatch('updateItem', JSON.parse(JSON.stringify(this.value))).then((itemUpdated) => {
				this.$emit('updated', itemUpdated)
			})
					.finally(() => {
						this.processing = false
					})
		},
    updateSeasonalPrice({ value, index }) {
      if(Array.isArray(this.value.seasonalPrices)) {
        if(value && index >= 0) {
          this.value.seasonalPrices[index] = value
        }
      }
    },
    removeSeasonalPrice(index) {
      if(Array.isArray(this.value.seasonalPrices) && index >= 0) {
        this.value.seasonalPrices.splice(index, 1)
      }
    },
    addNewSeasonalPrice() {
      if(!this.value.seasonalPrices) {
        this.value.seasonalPrices = []
      }
      if(typeof this.organization !== 'undefined' && this.organization !== null) {
        if(typeof this.organization.defaultCurrency !== 'undefined' && this.organization.defaultCurrency !== null) {
          if(typeof this.organization.defaultCurrency.code !== 'undefined' && this.organization.defaultCurrency.code !== null) {
            this.seasonalPriceObject.price.currency_code = this.organization.defaultCurrency.code
          }
        }
      }
      this.value.seasonalPrices.push(JSON.parse(JSON.stringify(this.seasonalPriceObject)))
    },
    addNewOpeningHour(openingHour) {
			if(!this.value.openingHours || !Array.isArray(this.value.openingHours) || this.value.openingHours.length < 1) {
				this.value.openingHours = []
			}
			this.value.openingHours.push(openingHour)
		},
    updateWeekdayPrice({ value, index }) {
      if(Array.isArray(this.value.weekdayPrices) && this.value.weekdayPrices.length > 0) {
        if(value && index >= 0) {
          this.value.weekdayPrices[index] = value
        }
      }
    },
    removeWeekdayPrice(index) {
      if(Array.isArray(this.value.weekdayPrices) && this.value.weekdayPrices.length > 0) {
        if(index >= 0) {
          this.value.weekdayPrices.splice(index, 1)
        }
      }
    },
    addNewWeekdayPrice(weekday) {
      if(!this.value.weekdayPrices) {
        this.value.weekdayPrices = []
      }
      if(typeof this.organization !== 'undefined' && this.organization !== null) {
        if(typeof this.organization.defaultCurrency !== 'undefined' && this.organization.defaultCurrency !== null) {
          if(typeof this.organization.defaultCurrency.code !== 'undefined' && this.organization.defaultCurrency.code !== null) {
            this.weekdayPriceObject.price.currency_code = this.organization.defaultCurrency.code
          }
        }
      }
      this.weekdayPriceObject.weekday = weekday
      this.value.weekdayPrices.push(JSON.parse(JSON.stringify(this.weekdayPriceObject)))
    },
    initializeUploadWidget() {
      const uploadPreset = 'product-upload'
      const folderName = this.cloudinaryFolderName
      try {
        return this.$store.dispatch('getCloudinarySignature', {folderName, uploadPreset}).then(signature => {
          // TODO: Display to the user that the image upload component does not work for some reason.
          if (!signature) {
            this.initializeError = true
            return null
          }
          return window.cloudinary.createUploadWidget({
            cloudName: 'salescloud',
            uploadPreset,
            folder: folderName,
            uploadSignature: signature.signature,
            uploadSignatureTimestamp: signature.timestamp,
            api_key: process.env.VUE_APP_CLOUDINARY_API_KEY // Cloudinary Public API_Key
          }, (error, result) => {
            if (!error && result && result.event === "success") {
              this.uploadComplete(result.info)
            }
            // In the case the dialog closes for some reason, we need to make sure the loading state is upadted on the upload button.
            if (error && result && result.event === 'close') {
              this.loadingUploadWidget = false
            }
            if (!error && result && result.event === 'close') {
              this.loadingUploadWidget = false
            }
            
          })
        })
      }catch(e) {
        // Display to the user that the image upload won't work
        this.initializeError = true
        return null
      }
    },
    initializeLocationPrices(currentLocationPrices) {
      const locationPrices = []
      if(this.locations && this.locations.length > 0) {
        for(const location of this.locations) {
          let beforeAmount = null
          if(currentLocationPrices && currentLocationPrices.length > 0) {
            const locationPriceBefore = currentLocationPrices.find(beforePrice => beforePrice.location === location.uuid)
            if(locationPriceBefore?.price) {
              beforeAmount = locationPriceBefore.price.amount
            }
          }
          locationPrices.push({
            location: location.uuid,
            price: {
              amount: beforeAmount,
              currency_code: this.value.price.currency_code
            }
          })
        }
      }
      return locationPrices
    }
	},
	async beforeMount() {

    if (this.value.sku === null || this.value.sku === undefined || this.value.sku === '') {
      this.refreshSku()
    }

		if((this.value.uiValues === null || this.value.uiValues === undefined) && this.uiFields) {
			this.value.uiValues = {}
			this.uiFields.forEach(field => {
				this.value.uiValues[field.key] = field.value || null
			})
		}

		const locationPricesBefore = this.value.locationPrices

		this.value.locationPrices = this.initializeLocationPrices(locationPricesBefore)
    
    this.uploadWidget = await this.initializeUploadWidget()

	},
  watch: {
    valid(value) {
      this.$emit('valid', value)
    }
  },
  mounted() {
    // Likely editing a giftcard and there's a set payment_method of type giftcard.
    if (this.value?.type === 'giftcard' && this.value.payment_instance) {
      this.value.payment_instance = this.value.payment_instance.legacyId
    }
    
    // Creating a giftcard and there is an avaiable payment_instance of type giftcard.
    if (this.value?.type === 'giftcard' && !this.value?.payment_instance) {
      this.value.payment_instance = this.paymentMethodInstances[0]?.legacyId
    }

    // Likely creating a giftcard and there's no available payment_method of type giftcard.
    if (this.value?.type === 'giftcard' && (!this.value?.payment_instance || this.paymentMethodInstances?.length === 0) && !this.value?.payment_instance) {
      // TODO - Display that we can't create the giftcard because there's no payment method of type giftcard.
      this.paymentMethodInstanceMissingWarning = "Missing a payment method instance of type giftcard. Please contact Salescloud for assistance on creating a giftcard payment method."
    }
  }
}
</script>

<style>
.ProseMirror {
	height: 200px;
}
</style>

